/**
 * @memberOf app
 * @ngdoc service
 * @name ColorSvc
 * @param {service} $rootScope Root Scope
 * @param {service} $timeout angular window.setTimeout service
 * @description
 *   Angular service for handling UI color functions and options
 */
app.service('ColorSvc', function ($rootScope, $timeout) {

	/**
	 * @lends ColorSvc
	 */
	var ColorSvc = this;

	/**
	 * @lends $rootScope
	 */
	var $rS = $rootScope;

	/**
	 * The angular-bound facing portion of the color service
	 * @type {Object}
	 */
	$rS.ColorSvc = {};

	/**
	 * Lists of colors
	 * @type {Object}
	 */
	$rS.ColorSvc.colors = pluto.VisualizationOption.colorArrays;


	/**
	 * Place to store colorservice functions
	 * @type {Object}
	 */
	$rS.ColorSvc.fn = {};

	/**
	 * Returns a string of CSS to make a gradient out of the array of colors
	 * @param  {array} colors - Array of CSS color value strings
	 */
	$rS.ColorSvc.fn.gradientBg = function (colors) {
		return colors && colors.length ?'background: linear-gradient(to right, ' + colors.join(',') + ');':'';
	};

});
