/**
 * @memberOf app
 * @ngdoc directive
 * @name navbar
 * @param {service} PlutoSvc
 * @description
 *   Navbar. Doesn't really do anything interesting yet.
 */
app.directive('navbar', function(PlutoSvc) {
	return {
		restrict: 'E',
		templateUrl: 'js/src/main/ng-partials/directives/navbar/navbar.html',
		link: function(scope) {
		}
	};
});
