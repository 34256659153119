/**
 * @memberOf app
 * @ngdoc service
 * @name PlutoSvc
 * @param {service} $rootScope Root Scope
 * @param {service} $timeout angular window.setTimeout service
 * @param {service} ColorSvc Our color service
 * @description
 *   Angular service for interacting with PLUTO data and dealing with calculating
 *   results of user inputs
 */
app.service('PlutoSvc', function ($rootScope, $timeout, ColorSvc) {

	/**
	 * @lends PlutoSvc
	 */
	var PlutoSvc = this;

	/**
	 * @lends $rootScope
	 */
	var $rS = $rootScope;

	/**
	 * Keyed object to strings representing events emitted by this service
	 * @type {object}
	 */
	PlutoSvc.EVENTS = {
		INIT: {
			// Initial calls for file names have been loaded
			AVAILABLE_NAMES_FILE_LOADED: "PlutoSvcEvents_init_availableNamesFileLoaded"
		},
		USER: {
			// The user has changed the analysis type (between comparison and single source)
			CUR_ANALYSIS_TYPE_CHANGED: "PlutoSvcEvents_user_curAnalysisTypeChanged"
		},
		LOAD: {
			// Analysis data has finished loading
			ANALYSIS_DATA_COMPLETED: "PlutoSvcEvents_load_analysisDataLoaded"
		}
	};

	createServiceFunctions();
	initServiceVariables();
	initRootScopeVariables();

	function createServiceFunctions() {

		/*
		███████ ███    ██
		██      ████   ██
		█████   ██ ██  ██
		██      ██  ██ ██
		██      ██   ████
		*/


		/**
		 * User (or us, initially) sets the mode we're using for analysis
		 * @param {pluto.AnalysisType} analysisType - Object representing the kind of analysis we're looking at
		 */
		PlutoSvc.setAnalysisType = function (analysisType) {
			/**
			 * Current active analysis
			 * @type {pluto.AnalysisType}
			 */
			$rS.PlutoSvc.curAnalysis = analysisType;
			$rS.$broadcast(PlutoSvc.EVENTS.USER.CUR_ANALYSIS_TYPE_CHANGED);
		};

		/**
		 * Get data for the analysis we're running from the server
		 */
		PlutoSvc.getSetData = function () {

			$rS.PlutoSvc.dataDownloaded = false;
			$rS.PlutoSvc.dataDownloading = true;

			var completed = 0;

			$rS.PlutoSvc.curAnalysis.namesChosen.forEach(function (name, i) {

				// Do not get if already cached
				if (pluto.loadedDataSet[name]) checkRenderData();

				PlutoSvc.data.loadDataSetFile(name)
					.then(function (rawData) {
						rawData.forEach(function (d) {
							Object.keys(d)
								.forEach(function (key) {
									if (typeof d[key] === 'string' && d[key].startsWith('b\'')) d[key] = '';
								});
						});

						checkRenderData();
					});

				function checkRenderData() {
					completed += 1;
					if (completed !== $rS.PlutoSvc.curAnalysis.namesChosen.length) return;
					if (i === 0) PlutoSvc.map.initPluto(name);
					$rS.PlutoSvc.dataDownloaded = true;
					$rS.PlutoSvc.dataDownloading = false;
					$rS.asyncApply();
					$rS.$broadcast(PlutoSvc.EVENTS.LOAD.ANALYSIS_DATA_COMPLETED);
				}

			});
		};

		PlutoSvc.getAnalysisName = function (i) {
			i = i || 0;
			var jsonDataName = $rS.PlutoSvc.curAnalysis.namesChosen[i];
			return jsonDataName;
		};

		PlutoSvc.getAnalysisCrossData = function (i) {
			i = i || 0;
			var jsonDataName = PlutoSvc.getAnalysisName(i);
			var crossData = pluto.loadedDataSet[jsonDataName];
			return crossData;
		};

		PlutoSvc.init = function (settingsFileName) {

			console.log('Begin PlutoSvc init with settings:',settingsFileName);

			return new Promise(function(resolve, reject) {
				PlutoSvc.setAnalysisType($rS.PlutoSvc.analyses[0]);

				PlutoSvc.data.availableNamesFile(settingsFileName)
					.then(function () {
						console.log('Read settings file');

						// Set available names with what comes back
						$rS.PlutoSvc.availableNames = pluto.availablePlutoNames;

						// Reset available dimensions
						pluto.DataFilter.availableDimensions = [];

						// Create objects that represent what types we're doing
						['linear', 'ordinal'].map(function (type) {
							pluto.availableFunctionNames[type].forEach(function (key) {
								pluto.DataFilter.availableDimensions.push({
									type: type,
									key: key
								});
							});
						});

						$rS.PlutoSvc.availableDimensions = pluto.DataFilter.availableDimensions;

						resolve();

					});
			});
		};
	}

	function initServiceVariables() {

		/*
		███████ ███████ ██████  ██    ██     ██    ██  █████  ██████  ███████
		██      ██      ██   ██ ██    ██     ██    ██ ██   ██ ██   ██ ██
		███████ █████   ██████  ██    ██     ██    ██ ███████ ██████  ███████
		     ██ ██      ██   ██  ██  ██       ██  ██  ██   ██ ██   ██      ██
		███████ ███████ ██   ██   ████         ████   ██   ██ ██   ██ ███████
		*/


		/**
		 * An object that allows us to get data from the server
		 * @type {pluto.Data}
		 */
		PlutoSvc.data = new pluto.Data();

		/**
		 * An object that allows us to perform map and map layer functions
		 * @type {pluto.Map}
		 */
		PlutoSvc.map = new pluto.Map();

	}

	function initRootScopeVariables() {

		/*
		██████  ███████     ██    ██  █████  ██████  ███████
		██   ██ ██          ██    ██ ██   ██ ██   ██ ██
		██████  ███████     ██    ██ ███████ ██████  ███████
		██   ██      ██      ██  ██  ██   ██ ██   ██      ██
		██   ██ ███████       ████   ██   ██ ██   ██ ███████
		*/

		/**
		 * Store plutoservice variables
		 * @type {Object}
		 */
		$rS.PlutoSvc = {};


		/**
		 * Available analysis modes
		 * @type {pluto.AnalysisType[]}
		 * @see pluto.AnalysisType
		 */
		$rS.PlutoSvc.analyses = [
			new pluto.AnalysisType({
				count: 1,
				label: 'Explore one data set',
				namesChosen: []
			}), new pluto.AnalysisType({
				count: 2,
				label: 'Compare two data sets against each other',
				namesChosen: []
			})
		];

		/**
		 * All available names. We'll populate later on init from server with ajax
		 * @type {string[]}
		 */
		$rS.PlutoSvc.availableNames = [];

		/**
		 * Whether we have completed downloading the data we want
		 * @type {Boolean}
		 */
		$rS.PlutoSvc.dataDownloaded = false;

		/**
		 * Whether we are currently fetching data
		 * @type {Boolean}
		 */
		$rS.PlutoSvc.dataDownloading = false;

		/**
		 * Current visualization
		 * @type {pluto.VisualizationOption}
		 */
		$rS.PlutoSvc.curVisualization = null;
	}

});
