/**
 * @memberOf app
 * @ngdoc directive
 * @name filterAnalysis
 * @param {service} PlutoSvc
 * @param {service} $rootScope
 * @param {service} $timeout
 * @description
 *   Each filter/analysis chart card is one of these directives. Does heavy lifting
 *   of engaging user interaction events and hooking into crossfiltering and re-visualization
 */
app.directive('filterAnalysis', function (PlutoSvc, $rootScope, $timeout) {
	return {
		restrict: 'E',
		templateUrl: 'js/src/main/ng-partials/directives/filterAnalysis/filterAnalysis.html',
		scope: {
			curDataFilter: '=',
			availableDimensions: '<'
		},
		link: function (scope, elem) {

			var graphDiv, mapLoadingDiv, debouncedMapDrawSignal;

			var $rS = $rootScope;

			createScopeFns();

			scope.wasFiltered = false;

			scope.numDimensions = 1;

			// We calculate new colors and draw map/coloring as filters are set,
			// debounced some number of ms
			debouncedMapDrawSignal = _.debounce(mapDrawSignal, 100);

			debouncedMapDraw = _.debounce(mapDraw, 500);

			$timeout(function () {
				// set this variable after template renders
				graphDiv = elem.find('.panel-body .filter-analysis-graph');
				mapLoadingDiv = $('#gmaps-loading');
			});

			/*
			███████  ██████  ██████  ██████  ███████     ███████ ███    ██
			██      ██      ██    ██ ██   ██ ██          ██      ████   ██
			███████ ██      ██    ██ ██████  █████       █████   ██ ██  ██
			     ██ ██      ██    ██ ██      ██          ██      ██  ██ ██
			███████  ██████  ██████  ██      ███████     ██      ██   ████
			*/

			function createScopeFns() {

				/**
				 * Remove this filter's effects
				 */
				scope.removeFilter = function () {
					// we may need to remove this dimension from the crossfilter first
					scope.clearField(0);
					scope.clearField(1);
					mapDrawSignal();
					$rS.$broadcast('remove-filter', scope.curDataFilter);
				};

				/**
				 * Toggle between one or two fields to compare
				 */
				scope.toggleFieldCount = function () {

					if (scope.numDimensions < 2) {
						if (scope.curDataFilter.dimensions[0] && scope.curDataFilter.dimensions[0].type !== 'linear') scope.clearField(0);
						scope.numDimensions = 2;
						graphDiv.html('');
					} else if (scope.numDimensions >= 2) {
						scope.clearField(1);
						scope.numDimensions = 1;
						scope.chooseDimension(0, scope.curDataFilter.dimensions[0]);
					}
				};

				/**
				 * Clear field at index. Redraws all DC.js graphs after
				 * @param  {number} i - Index to clear
				 */
				scope.clearField = function (i) {
					graphDiv.html('');
					disposeOldChart();
					if (scope.curDataFilter.dimensions[i]) {
						scope.curDataFilter.dimensions[i].active = false;
					}
					scope.curDataFilter.chooseDimension(i, null);
					dc.redrawAll();
					if (scope.wasFiltered) debouncedMapDrawSignal();
					scope.wasFiltered = false;
				};

				/**
				 * Choose dimension at Index
				 * @param  {number} i                  - Index to set
				 * @param  {dataFilterDimension} availableDimension - Dimension to set when calling dataFilter.chooseDimension
				 * @see pluto.DataFilter
				 */
				scope.chooseDimension = function (i, availableDimension) {

					var prevDimension = scope.curDataFilter.dimensions[i];

					if (isCurViz() && prevDimension && (prevDimension.type !== availableDimension.type)) $rS.PlutoSvc.curVisualization.selectedColors = null;

					scope.clearField(i);

					if (availableDimension) availableDimension.active = true;

					scope.curDataFilter.chooseDimension(i, availableDimension);

					scope.checkColors(true);

				};

				/**
				 * Choose to visualize by this filter
				 */
				scope.visualizeBy = function () {

					if ($rS.PlutoSvc.curVisualization.dataFilter === scope.curDataFilter) return; // we're already doing this

					// scope.clearFilter();

					$timeout(function () {
						$rS.PlutoSvc.curVisualization = new pluto.VisualizationOption({
							dataFilter: scope.curDataFilter
						});
						scope.checkColors();
					});

				};

				/**
				 * Checks colors to see whether we are supposed to be visualizing by this filter.
				 * If so, set colors.
				 */
				scope.checkColors = function (isNew) {
					if (!isCurViz()) return draw();

					$rS.$broadcast('calculate-other-colors');
					$rS.PlutoSvc.curVisualization.ensureColorInit();
					draw();

					function draw() {
						$timeout(function () {
							return isNew ? drawChart() : redrawExistingChart();
						});
					}
				};

				/**
				 * Choose a set of colors as our new Visualization
				 * @param  {string[]} colorOption - Array of string colors
				 */
				scope.chooseColorOption = function (colorOption) {
					$rS.PlutoSvc.curVisualization.selectedColors = colorOption;
					scope.checkColors();
				};

				scope.calculateClamps = function (dim) {
					// Clear all brushes first
					disposeOldChart();
					scope.wasFiltered = false;
					dim.clamps.active = true;
					$timeout(drawChart);
				};

				scope.removeClamps = function (dim) {
					disposeOldChart();
					dim.clamps = undefined;
					$timeout(function () {
						drawChart();
						redrawChartsAndMaps();
					});
				};

				scope.clearFilter = function () {
					scope.curDataFilter.dcChart.filterAll();
					scope.wasFiltered = false;
					redrawChartsAndMaps();
				};

			}

			/*
			███████ ██    ██ ███████ ███    ██ ████████ ███████
			██      ██    ██ ██      ████   ██    ██    ██
			█████   ██    ██ █████   ██ ██  ██    ██    ███████
			██       ██  ██  ██      ██  ██ ██    ██         ██
			███████   ████   ███████ ██   ████    ██    ███████
			*/

			scope.$on('redraw-map', debouncedMapDraw);


			scope.$on('calculate-other-colors', function () {
				// If we were not the filter analysis that triggered the calculate other colors
				// call, draw chart again. (this will set existing coloring to neutral if we were
				// coloring by this option before)
				if (!isCurViz()) {
					redrawExistingChart();
				}
			});

			/*
			 █████  ███    ██  ██████  ███    ██     ███████ ███    ██
			██   ██ ████   ██ ██    ██ ████   ██     ██      ████   ██
			███████ ██ ██  ██ ██    ██ ██ ██  ██     █████   ██ ██  ██
			██   ██ ██  ██ ██ ██    ██ ██  ██ ██     ██      ██  ██ ██
			██   ██ ██   ████  ██████  ██   ████     ██      ██   ████
			*/

			function redrawChartsAndMaps() {
				dc.redrawAll();
				debouncedMapDrawSignal();
			}

			/**
			 * Create chart if ready
			 */
			function drawChart() {

				var readyToDraw = _.range(scope.numDimensions)
					.reduce(function (prev, cur, i) {
						return Boolean(scope.curDataFilter.dimensions[i]) && prev;
					}, true);

				scope.extents = null;

				if (!readyToDraw) return;

				scope.curDataFilter.createChart(graphDiv, {
					crossData: PlutoSvc.getAnalysisCrossData(0),
					map: PlutoSvc.map,
					jsonDataName: PlutoSvc.getAnalysisName(0),
					curVisualization: $rS.PlutoSvc.curVisualization
				});

				var validDims = scope.curDataFilter.dimensions.filter(function (dim) {
					return dim && dim.type;
				});

				scope.extents = validDims.map(function (dim) {
					return scope.curDataFilter.getExtent(dim.key);
				});

				validDims.forEach(function (dim, i) {
					if (dim && dim.active) {
						dim.clamps = dim.clamps || angular.copy(scope.extents[i]);
					}
				});

				if (isCurViz()) debouncedMapDrawSignal();

				scope.curDataFilter.dcChart.on('filtered', function () {
					scope.wasFiltered = true;

					var dataLeft = scope.curDataFilter.chart.cdim.top(Infinity);

					scope.filteredExtents = scope.curDataFilter.dimensions.map(function (dim) {

						var vals = dataLeft.map(function (d) {
							var val = d[dim.key];
							return val === '' ? '-blank-' : val;
						});

						if (dim.type === 'ordinal') return _.uniq(vals)
							.sort();
						if (dim.type === 'linear') return d3.extent(vals);

					});

					debouncedMapDrawSignal();
					$timeout(function () {
						scope.$apply();
					});
				});
			}

			function redrawExistingChart() {
				if (!scope.curDataFilter || !scope.curDataFilter.chartConfig) return;
				scope.curDataFilter.chartConfig.curVisualization = $rS.PlutoSvc.curVisualization;
				scope.curDataFilter.drawChart();
				if (isCurViz()) debouncedMapDrawSignal();
			}

			/**
			 * Gets rid of an old chart (called when overriding or un-setting)
			 */
			function disposeOldChart() {
				if (scope.curDataFilter.chart) scope.curDataFilter.chart.disposeDimension();
			}

			/**
			 * Send signal that map wants to be redrawn
			 */
			function mapDrawSignal() {
				mapLoadingDiv.show();
				$rS.$broadcast('redraw-map');
			}

			/**
			 * Draw map
			 */
			function mapDraw() {
				if (!isCurViz()) return;
				scope.curDataFilter.drawMap()
					.then(function () {
						mapLoadingDiv.hide();
						$rS.asyncApply();
					});

			}

			function isCurViz() {
				return scope && scope.curDataFilter && $rS.PlutoSvc.curVisualization && (scope.curDataFilter === $rS.PlutoSvc.curVisualization.dataFilter);
			}
		}
	};
});
