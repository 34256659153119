/**
 * @memberOf app
 * @ngdoc directive
 * @name chooseAnalysis
 * @param {service} $rootScope
 * @param {service} PlutoSvc
 * @description
 *   Choose an analysis type (which PLUTO year? two-year comparison or just one?)
 */
app.directive('chooseAnalysis', function (PlutoSvc, $rootScope) {
	return {
		restrict: 'E',
		templateUrl: 'js/src/main/ng-partials/directives/chooseAnalysis/chooseAnalysis.html',
		link: function (scope) {

			var $rS = $rootScope;

			scope.setAnalysisType = function (analysisType) {
				PlutoSvc.setAnalysisType(analysisType);
			};

			scope.choseSet = function (i, name) {
				$rS.PlutoSvc.curAnalysis.namesChosen[i] = name;
				PlutoSvc.getSetData();
			};

			init();

			// INIT
			// Only supports single data set, not comparison mode
			function init() {

				// load a file by default, if we were asked for it
				var initIndex = pluto.data.settings.raw.defaultJsonDataSetIndex;
				if (initIndex !== undefined && Number.isInteger(initIndex)) {
					scope.choseSet(0, pluto.availablePlutoNames[initIndex]);
				}

				if (pluto.data.settings.raw.hideDataChooser) {
					scope.hideChoosers = true;
				}


			}


		}
	};
});
