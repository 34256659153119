/**
 * Base state for all nested future states we may have
 */
app.config(function ($stateProvider) {
	$stateProvider.state('root', {
		name: 'root',
		url: '/?set',
		templateUrl: 'js/src/main/ng-partials/states/root.html',
		controller: 'RootStateCtrl',
		resolve: {
			PlutoSvcInit: function (PlutoSvc, $stateParams) {
				return PlutoSvc.init($stateParams.set || 'default');
			}
		}
	});
});

app.controller('RootStateCtrl', function ($rootScope, $scope, PlutoSvc) {

	/**
	 * @lends $rootScope
	 */
	var $rS = $rootScope;

	createFunctions();
	createEventHandlers();

	$scope.title = 'Pluto Viewer';

	$scope.dataFilters = [];

	$scope.addDimension();

	// $rS.PlutoSvc.curVisualization.dataFilter

	function createFunctions() {
		$scope.addDimension = function () {
			$scope.dataFilters.push(new pluto.DataFilter());
			setCurVizIfNone();
		};
	}

	function createEventHandlers() {
		$scope.$on('remove-filter', function (event, data) {
			if (data === $rootScope.PlutoSvc.curVisualization.dataFilter) {
				$rootScope.PlutoSvc.curVisualization = null;
			}
			$scope.dataFilters.splice($scope.dataFilters.indexOf(data), 1);
			setCurVizIfNone();
		});
	}

	function setCurVizIfNone() {
		if (!$rootScope.PlutoSvc.curVisualization && $scope.dataFilters.length) {
			$rootScope.PlutoSvc.curVisualization = new pluto.VisualizationOption({
				dataFilter: $scope.dataFilters[$scope.dataFilters.length - 1]
			});
		}
	}

});
