// // Main
// window.onload = function() {
//     var mlbApp = pluto.Main();
//     mlbApp.run();
// };

/**
 * Angular application. See Angular-specific documentation for anything inside
 * of app.something.
 * @type {object}
 * @global
 */
var app = angular.module('plutoviewer', ['ui.router']);


/**
 * @memberOf app
 * @ngdoc Globals
 * @name $rootScope
 */
app.run(function ($rootScope, $timeout, PlutoSvc) {

	/**
	 * Re-apply data bindings to DOM after current digest loop completes
	 */
	$rootScope.asyncApply = function () {
		$timeout(function () {
			$rootScope.$apply();
		});
	};

	$rootScope.getCountArray = function (num) {
		return _.range(num);
	};

});

// ADD TO ANOTHER LIBRARY LATER
var KPF = KPF || {};

KPF.ui = KPF.ui || {};

KPF.ui.color = KPF.ui.color || {};

KPF.ui.color.hexToRgb = function (hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function (m, r, g, b) {
		return r + r + g + g + b + b;
	});

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
};
