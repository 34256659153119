/**
 * @memberOf app
 * @ngdoc directive
 * @name gmaps
 * @param {service} PlutoSvc
 * @param {service} $rootScope
 * @param {service} $timeout
 * @description
 *   Creates gmaps in full-screen div
 */
app.directive('gmaps', function (PlutoSvc,$rootScope,$timeout) {
	return {
		restrict: 'E',
		templateUrl: 'js/src/main/ng-partials/directives/gmaps/gmaps.html',
		link: function (scope) {
			// creates the map
			PlutoSvc.map.createMap('gmaps');

			// scope.$on('calculate-colors', function(){
			// 	$timeout(drawMap);
			// });
		}
	};
});
